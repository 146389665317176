import { createClient, ClientInterface } from './api/createClient'
import { getError } from '@pismo/sdk-core'
import { IError, ISuccess } from './interfaces'
import { formatDate } from 'src/utils/date'

export interface merchantsInterface {
  headers: {
    Authorization: string
    'x-tenant': string
    'x-protocol'?: string
  }
}

export interface IResult {
  total_items: number
  per_page: number
  pages: number
  current_page: number
}
export interface ICustomFields {
  cnes: string
  crm: string
  doctor_name: string
  notes: string
  create?: boolean
}

export interface ITerminal {
  type: string
  provider: string
  quantity: number
  active: boolean
}

export interface IAddress {
  zip_code: string
  city: string
  country: string
  number: number
  state: string
  address: string
  neighborhood: string
  complementary_address?: string
}

export interface IMerchant extends IAddress {
  document_number: string
  marketplace_category: string | null
  latitude: string | null
  municipal_registration: string
  merchant_key: number
  merchant_name: string
  contact: string
  adicional_phone: string | null
  key_check_digit: 2
  id: number
  email: string
  longitude: string | number
  area_code: string
  phone: string
  name: string
  aditional_area_code: string | null
  category: number
  creditor_id: number
  status: string
  custom_fields: ICustomFields
  terminals: ITerminal[]
  creditor?: ICreditorResult
}

export interface IMerchantNew extends IAddress {
  program_id: number
  document_number: string
  name: string
  category: number
  email: string
  area_code: string
  phone: string
  creditor: CreditorResult
  terminals: ITerminal[]
  custom_fields: Partial<ICustomFields>
  brand_name?: string
  contact?: string
  additional_area_code?: string
  additional_phone?: string
}

export interface IPutMerchant extends IMerchant {
  id: number
}

export interface IMerchantResult extends IResult {
  items: IMerchant[]
}

export interface IBank {
  id: number
  agency: string
  account: string
  code: string
  name: string
}

export interface IAccount {
  id: number
}

export interface CreditorResult {
  id: number
  name: string
  bank_code: string
  advancement_fee: number
  discount_rate: number
  maximum_advancement_percentage: number
  crediting_method: string
  crediting_frequency: string
  crediting_frequency_day: number
  bank: IBank
  account: IAccount
}

export interface TransactionResult {
  order_id: number
  net_value: number
  authorization_id: string | number
  receipt_date: string
  discount: number
  received_value: number
  type: string
  number_of_installments: number
  gross_value: number
  advanced_amount: number
  installment: number
  operation_type: number
  id: number
  payment_date: string
  operation: string
  event_date: string
  status: string
  meta: [
    {
      name: string
      value: string
    },
  ]
}

export interface IAntecipateResultItems {
  [x: string]: any
  payment_date: string
  gross_value: number
  discount: number
  net_value: number
  max_advancement_amount: number
  advancement_discount_amount: number
  advancement_amount: number
}

export interface IGetCreditorsResult extends IResult {
  items: CreditorResult[]
}

export interface IAntecipateResult {
  items: IAntecipateResultItems
  totals: {
    gross_value: number
    discount: number
    net_value: number
    max_advancement_amount: number
    advancement_discount_amount: number
    advancement_amount: number
  }
  tax: {
    rav: {
      tax: number
      tariff: number
      cet: number
      percentage: number
      interest_method: string
      group_transactions: string
      initial_transaction_status: string
    }
  }
}

export interface IGetTransactionResult extends IResult {
  items: TransactionResult[]
}

export interface IGet {
  name?: string
  documentNumber?: string
  page?: number
  perPage?: number
  order?: string
}

export interface IGetMerchants extends IGet {
  marketplaceID?: number
}

export interface IGetCreditor {
  name?: string
  page?: number
  perPage?: number
}

export interface IBankCreditor {
  id: number | string
  type: string
  agency: string
  account: string
  code: number
  name: string
  agency_number?: number
  agency_dv?: number
  account_number?: number
  account_dv?: number
}

export interface IOperatorsCreditor {
  matched_settlement: boolean
  bank_name: string
  matched_mdr: boolean
  operation_id: number
  nth_installment_interval: number
  doc_number_allowed: boolean
  first_installment_interval: number
  transaction_fee: number
  mdr: number
  not_card_allowed: boolean
  operation_type: {
    id: number
  }
  org_operation: {
    id: number
  }
}

export interface IPutCreditor {
  id: number | string
  name: string
  bank_code: number
  advancement_fee: number
  discount_rate: number
  maximum_advancement_percentage: number
  crediting_method: string
  crediting_frequency: string
  crediting_frequency_day: any
  cedente_id: number
  bank: IBankCreditor
  account: {
    id: number
  }
  operations?: IOperatorsCreditor[]
}

export interface ISetCreditor {
  operations: [
    {
      operation_type: {
        id: number
      }
      mdr: number
      first_installment_interval: number
      nth_installment_interval: number
      transaction_fee: number
      matched_settlement: boolean
      matched_mdr: boolean
      not_card_allowed: boolean
      doc_number_allowed: boolean
    },
  ]
  name: string
  crediting_method: string
  bank: {
    id: number
    account: string
    agency: string
  }
  crediting_frequency: string
  crediting_frequency_day?: number
  cedente_id: number
  advancement_fee: number
  discount_rate?: number
  maximum_advancement_percentage?: number
}

export interface ICreditorResult {
  id: number
  name: string
  bank_code: number
  advancement_fee: number
  discount_rate: number
  maximum_advancement_percentage: number
  crediting_method: string
  crediting_frequency: string
  crediting_frequency_day: number
  cedente_id: number
  bank: {
    id: number
    type: string
    agency: string
    account: string
    code: number
    name: string
  }
  account: {
    id: number
  }
  operations: [
    {
      matched_settlement: boolean
      bank_name: string
      matched_mdr: boolean
      operation_id: number
      nth_installment_interval: number
      doc_number_allowed: boolean
      first_installment_interval: number
      transaction_fee: number
      mdr: number
      not_card_allowed: boolean
      operation_type: {
        id: number
      }
      org_operation: {
        id: number
      }
    },
  ]
  marketplaces?: [
    {
      name: string
      id: number
    },
  ]
}

export interface IGetTransactions extends IGet {
  order?: string
  sortBy?: string
  groupBy?: string
  operation?: string
  startDate?: Date
  endDate?: Date
  startEventDate?: Date
  endEventDate?: Date
  endPaymentDate?: Date
  startPaymentDate?: Date
  startReceiptDate?: Date
  endReceiptDate?: Date
  status?: string
  authorization_id?: number
  marketplace?: string
}

export interface IBlockUnblock {
  gross: number
  discount: number
  net: number
}

export interface ITransactionsTotalItem {
  blocked: IBlockUnblock
  unblocked: IBlockUnblock
  discount: number
  gross: number
  net: number
}

export interface ITransactionsTotal {
  all: ITransactionsTotalItem
  pending: ITransactionsTotalItem
  scheduled: ITransactionsTotalItem
  settled: ITransactionsTotalItem
  paid: ITransactionsTotalItem
}

export interface IUpdateStatusTransaction {
  merchantId: number
  id: number
  status: string
}

export interface ITransactionType {
  discount_total: number
  gross_value_total: number
  net_value_total: number
  type: string
}

export interface IOperation extends Omit<ITransactionType, 'discount_total' | 'type'> {
  discount_value_total: number
  operation: string
}

export interface IMdrItem {
  daysToPayment: number
  description: string
  mdr: number
  operationType: number
}

export interface IRav {
  cet: number
  percentage: number
  tariff: number
  tax: number
}

export interface ITax {
  mdr: IMdrItem[]
  rav: IRav
}

export interface IMarketplace {
  brand_name: string
  document_number: string
  id: number
  name: string
}

export interface IMarketplaceResult extends IResult {
  items: IMarketplace[]
}

export interface ITransactionDetailsResult {
  transaction_auth_id: number
  card: {
    first_four_digits: number
    last_four_digits: number
  }
  total: number
  date: string
  email: string
  name: string
  installments: number
  authorization_code: number
  account_id: number
  items: any
  merchant: {
    name: string
    email: string
  }
}

export interface IDocuments {
  id: number
  mime_type: string
  name: string
  url: string
}

export interface ISetAntecipate {
  merchantId: number
  begin_date: string
  end_Date: string
  amount: number
}

export interface IMarketplacesMerchantsList {
  discount: number
  gross_value: number
  merchant: {
    id: number
    name: string
  }
}

export interface IMarketplacesMerchantsListResult extends IResult {
  items: IMarketplacesMerchantsList[]
}

export type statuses = 'APROVED' | 'PENDING' | 'REJECTED' | 'ANALYSING' | 'CANCELLED' | 'ACTIVE'

export interface IGetOperationsTypeBase {
  paymentDate: Date
  transactionType: string
}

export interface IGetOperationsTypeBaseMerchants {
  merchantID: number
}

export interface IGetOperationsTypeBaseMarketplace {
  marketplaceID: number
}

export type IGetOperationsType = IGetOperationsTypeBase &
  (IGetOperationsTypeBaseMerchants | IGetOperationsTypeBaseMarketplace)

export interface IGetTransactionsTotalFull {
  merchantID: number
  startDate: Date
  endDate: Date
  marketplaceID?: number
  operation?: string
  transactionType?: string
}

export interface IGetPagination {
  page?: number
  perPage?: number
  order?: number
}

export interface IPaginationResult {
  current_page: number
  per_page: number
  total_items: number
  pages: number
}

export interface CedentRequired {
  bank_id: number
  agencia: string
  conta_corrente: string
  carteira: number
  cedente: string
  especie: string
  especie_documento: string
  aceite: boolean
  local_pagamento: string
  instrucoes_pagamento: string
  convenio: string
  agencia_dv?: string
  convenio_dv?: string
  conta_corrente_dv?: string
}

export interface Cedent extends CedentRequired {
  cedente_id: number
  org_id: string
  bank_code: string
  bank_name: string
}

export interface CedentsResult extends IPaginationResult {
  items: Cedent[]
}

export interface CedentsProps extends IGetPagination {
  name?: string
}

export interface IMerchantItemsProps extends IGetPagination {
  total?: number
}
export interface Items {
  code: string
  description: string
  id: number
  price: number
}

export interface IGetItemsResult extends IPaginationResult {
  items: Items[]
}

export interface CNAE {
  business_category_id: number
  external_code: string
  Category: string
  description: string
}

export interface Bank {
  bank_id: number
  bank_code: string
  bank_i_s_p_b: any
  bank_name: string
  bank_i_s_p_b_str: any
}

export interface BusinessCategories {
  has_next: boolean
  per_page: number
  current_page: number
  items: CNAE[]
}

export interface BusinessCategoriesResult {
  data: BusinessCategories
}

export const Merchants = ({ headers }) => {
  const baseURL = String(process.env.REACT_APP_API_ADDRESS)
  const client = createClient({ baseURL: `${baseURL}/merchants`, headers })

  return {
    getClient(): ClientInterface {
      return client
    },

    async getMerchants(props?: IGetMerchants) {
      const { documentNumber = '', name = '', page = '1', perPage = '15', marketplaceID, order = 'dsc' } = props ?? {}
      const params = { documentNumber, name, page: page.toString(), perPage: perPage.toString(), order }
      const qs = new URLSearchParams(params).toString()

      try {
        const { data } = (await client.get(`/v1/merchants?${qs}`, {
          headers: marketplaceID ? { ...headers, 'x-marketplace-id': marketplaceID } : headers,
        })) as any

        return data
      } catch (err) {
        return err
      }
    },

    async getMerchant(merchantId: number) {
      try {
        const { data } = (await client.get(`/v1/merchants/${merchantId}`)) as any

        return data as IMerchant
      } catch (err) {
        return err
      }
    },

    async createMerchant(newMerchant: IMerchantNew) {
      return await client.post('/v1/merchants', newMerchant)
    },

    async updateMerchant(merchantID: number, data: IPutMerchant & Record<string, any>): Promise<ISuccess | unknown> {
      return client.put(`/v1/merchants/${merchantID}`, data)
    },

    async getCreditors(props?: IGet) {
      const { name = '', page = 1, perPage = 15, order = 'dsc' } = props ?? {}

      return await client.get(`/v1/creditors?name=${name}&page=${page}&perPage=${perPage}&order=${order}`)
    },

    async getCreditor(creditorID: number): Promise<ICreditorResult | IError> {
      try {
        const { data } = await client.get(`/v1/creditors/${creditorID}`)
        return data as ICreditorResult
      } catch (err) {
        return getError(err)
      }
    },

    async updateCreditors(creditorID: number, props: IPutCreditor): Promise<ISuccess | IError> {
      return await client.put(`v1/creditors/${creditorID}`, props)
    },

    async setCreditors(props: ISetCreditor) {
      return await client.post('v1/creditors', props)
    },

    async getTransactions(merchantID: number, props?: IGetTransactions) {
      const {
        startEventDate = '',
        endEventDate = '',
        startPaymentDate = '',
        endPaymentDate = '',
        startReceiptDate = '',
        endReceiptDate = '',
      } = props ?? {}

      const params = {
        ...props,
        order: '',
        page: 1,
        sortBy: '',
        startEventDate: startEventDate ? String(formatDate(startEventDate)) : '',
        endEventDate: endEventDate ? String(formatDate(endEventDate)) : '',
        startPaymentDate: startPaymentDate ? String(formatDate(startPaymentDate)) : '',
        endPaymentDate: endPaymentDate ? String(formatDate(endPaymentDate)) : '',
        startReceiptDate: startReceiptDate ? String(formatDate(startReceiptDate)) : '',
        endReceiptDate: endReceiptDate ? String(formatDate(endReceiptDate)) : '',
      }

      let sanitizedParams = Object.keys(params)
        .filter((item) => !!params[item])
        .reduce((accumulator, currentValue) => ({ ...accumulator, [currentValue]: params[currentValue] }), {})

      const qs = new URLSearchParams(sanitizedParams).toString()

      return await client.get(`/v3/merchants/${merchantID}/transactions?${qs}`)
    },

    async getTransactionsTotal(merchantID: number, startDate: Date, endDate: Date) {
      // 'pismo-sdk:Merchants:: getTransactionsTotal method has been deprecated please use getTransactionsTotalFull method'

      const start = formatDate(startDate)
      const end = formatDate(endDate)

      const blockUnblockDefault: IBlockUnblock = { gross: 0, discount: 0, net: 0 }
      const resultItemDefault: ITransactionsTotalItem = {
        blocked: { ...blockUnblockDefault },
        unblocked: { ...blockUnblockDefault },
        discount: 0,
        gross: 0,
        net: 0,
      }
      const resultDefault: ITransactionsTotal = {
        all: { ...resultItemDefault },
        pending: { ...resultItemDefault },
        scheduled: { ...resultItemDefault },
        settled: { ...resultItemDefault },
        paid: { ...resultItemDefault },
      }

      const { data } = (await client.get(
        `/v1/merchants/${merchantID}/transactions-totals?startPaymentDate=${start}&endPaymentDate=${end}`,
      )) as any

      return { ...resultDefault, ...data } as ITransactionsTotal
    },

    async updateStatusTransaction(
      merchantID: number,
      id: number,
      data: IUpdateStatusTransaction & Record<string, any>,
    ) {
      try {
        await client.patch(`/v1/merchants/${merchantID}/transactions/${id}`, data)

        return { success: true }
      } catch (err) {
        return err
      }
    },

    async getTransactionsType(merchantID: number, paymentDate: Date) {
      const date = formatDate(paymentDate)

      return await client.get(`/v2/merchants/${merchantID}/transactions-types?paymentDate=${date}`)
    },

    async getOperations(merchantID: number, paymentDate: Date, transactionType: string) {
      // pismo-sdk:Merchants:: getOperations method has been deprecated please use getOperationsType method'

      const date = formatDate(paymentDate)

      return await client.get(
        `/v3/merchants/${merchantID}/operations?paymentDate=${date}&transactionType=${transactionType}`,
      )
    },

    async getTax(merchantID: number) {
      try {
        const { data } = (await client.get(`/v1/merchants/${merchantID}/transactions/tax`)) as any
        return data as ITax
      } catch (err) {
        return err
      }
    },

    async getMarketplaceDetails(merchantID: number, idTransaction: number) {
      return await client.get(`/v2/merchants/${merchantID}/transactions/${idTransaction}`)
    },

    async getDocumentsList(merchantID: number) {
      try {
        const { data } = (await client.get(`/v1/merchants/${merchantID}/documents`)) as any

        return data as IDocuments[]
      } catch (err) {
        return err
      }
    },

    async deleteDocument(merchantID: number, documentID: number) {
      try {
        ;(await client.delete(`/v1/merchants/${merchantID}/documents/${documentID}`)) as any

        return { success: true }
      } catch (err) {
        return err
      }
    },

    async registerDocument(merchantID: number, documents) {
      try {
        const { data } = (await client.post(`/v1/merchants/${merchantID}/documents`, {
          merchantId: merchantID,
          documents,
        })) as any

        if (data.length === documents.length) {
          return { success: true, ids: data }
        }
      } catch (err) {
        return err
      }
    },

    async getWorkflowStatuses(merchantID: number) {
      try {
        const { data } = (await client.get(`/v1/merchants/${merchantID}/workflow/statuses`)) as any
        return data as statuses[]
      } catch (err) {
        return err
      }
    },

    async changeWorkflowStatus(merchantID: number, status: statuses, notes: string = '') {
      return client.put(`/v1/merchants/${merchantID}/workflow/status`, { notes, status })
    },

    async getAntecipate(merchantID: number, paymentDate?: Date): Promise<any> {
      try {
        const { data } = (await client.get(`v1/merchants/${merchantID}/advancements`)) as any
        return data as IAntecipateResult
      } catch (err) {
        return err
      }
    },

    async setAntecipate(merchantID: number, dataAntecipate: ISetAntecipate) {
      try {
        await client.post(`v2/merchants/${merchantID}/advancements`, dataAntecipate)
      } catch (err) {
        throw err
      }
    },

    async getMarketplacesMerchantList(marketplaceID: number) {
      try {
        const { data } = (await client.get(
          `v1/marketplaces/${marketplaceID}/merchant-transactions?groupby=merchant`,
        )) as any

        return data as IMarketplacesMerchantsListResult
      } catch (err) {
        return err
      }
    },

    async getMarketplaceMerchantTransactions(
      marketplaceID: number,
      version: 2 | 3,
      props: IGetTransactions,
      merchantID?: number,
    ) {
      try {
        const opt = Object.keys(props).reduce((obj, key) => {
          if (props[key]) {
            const val = key.match(/date/gi) ? formatDate(props[key]) : props[key]

            return { ...obj, [key]: val }
          }

          return obj
        }, {})

        const params = new URLSearchParams(opt).toString()

        const url =
          version === 2
            ? `v2/marketplaces/${marketplaceID}/merchants-transactions?${params}`
            : `v3/merchants/${merchantID}/transactions?${params}&marketplace=${marketplaceID}`

        const { data } = (await client.get(url)) as any

        return data as IGetTransactionResult
      } catch (err) {
        return err
      }
    },

    async getMarketplaceMerchantsTransactionsType(
      marketplaceID: number,
      version: 2 | 3,
      paymentDate: Date,
      merchantID?: number,
    ) {
      try {
        const url =
          version === 2
            ? `v2/marketplaces/${marketplaceID}/merchants-transactions-types?paymentDate=${formatDate(paymentDate)}`
            : `v3/merchants/${merchantID}/transactions-types?marketplace=${marketplaceID}&paymentDate=${formatDate(
                paymentDate,
              )}`

        const { data } = (await client.get(url)) as any

        return data as ITransactionType[]
      } catch (err) {
        return err
      }
    },

    async getOperationsType(props: IGetOperationsType) {
      try {
        const { paymentDate, transactionType } = props
        const marketplaceID = (props as IGetOperationsTypeBaseMarketplace).marketplaceID
        const merchantID = (props as IGetOperationsTypeBaseMerchants).merchantID

        const date = formatDate(paymentDate)

        const base =
          marketplaceID && !merchantID
            ? `/v2/marketplaces/${marketplaceID}/merchants-operations?fetchAll=true`
            : `/v3/merchants/${merchantID}/operations?${marketplaceID ? `&marketplace=${marketplaceID}` : ''}`

        const { data } = (await client.get(`${base}&paymentDate=${date}&transactionType=${transactionType}`)) as any

        return data as IOperation[]
      } catch (err) {
        return err
      }
    },

    async getTransactionsTotalFull(props: IGetTransactionsTotalFull) {
      try {
        const { merchantID, startDate, endDate, marketplaceID, operation, transactionType } = props

        const start = formatDate(startDate)
        const end = formatDate(endDate)

        const blockUnblockDefault: IBlockUnblock = { gross: 0, discount: 0, net: 0 }
        const resultItemDefault: ITransactionsTotalItem = {
          blocked: { ...blockUnblockDefault },
          unblocked: { ...blockUnblockDefault },
          discount: 0,
          gross: 0,
          net: 0,
        }
        const resultDefault: ITransactionsTotal = {
          all: { ...resultItemDefault },
          pending: { ...resultItemDefault },
          scheduled: { ...resultItemDefault },
          settled: { ...resultItemDefault },
          paid: { ...resultItemDefault },
        }

        const { data } = (await client.get(
          `/v1/merchants/${merchantID}/transactions-totals?startPaymentDate=${start}&endPaymentDate=${end}${
            marketplaceID ? `&marketplace=${marketplaceID}` : ''
          }${transactionType ? `&transactionType=${transactionType}` : ''}${
            operation ? `&operation=${operation}` : ''
          }`,
        )) as any

        return { ...resultDefault, ...data } as ITransactionsTotal
      } catch (err) {
        return err
      }
    },

    async getCedents(props?: CedentsProps) {
      const { name = '', page = 1, perPage = 15, order = 'dsc' } = props ?? {}

      return await client.get(`/v1/cedentes?name=${name}&page=${page}&perPage=${perPage}&order=${order}`)
    },

    async getCedent(cedentId: number) {
      return await client.get(`/v1/cedentes/${cedentId}`)
    },

    async setCedent(cedent: Cedent): Promise<any | IError> {
      return await client.put(`/v1/cedentes/${cedent.cedente_id}`, cedent)
    },

    async registerCedent(cedent: CedentRequired) {
      return await client.post('/v1/cedentes', cedent)
    },

    async getMerchantItems(merchantID: number, pagination?: IMerchantItemsProps) {
      const { page = 1, perPage = 10, total = 0 } = pagination ?? {}
      try {
        const { data } = (await client.get(
          `/v1/merchants/${merchantID}/items?page=${page}&perPage=${perPage}&total=${total}`,
        )) as any

        return data as IGetItemsResult
      } catch (err) {
        return err
      }
    },

    async getBusinessCategories(): Promise<BusinessCategories | unknown> {
      try {
        const { data } = (await client.get(
          `/v1/businesscategories?page=${0}&perPage=${1000}`,
        )) as BusinessCategoriesResult

        return data.items
      } catch (err) {
        return []
      }
    },

    async changeItem(merchantID: number, item: Items) {
      try {
        const { data } = (await client.put(`/v1/merchants/${merchantID}/items/${item.id}`, {
          ...item,
          merchant_id: Number(merchantID),
        })) as any

        if (data.id && data.id === item.id) {
          return { success: true }
        }
      } catch (err) {
        return err
      }
    },

    async deleteItem(merchantID: number, itemID: number) {
      try {
        ;(await client.delete(`/v1/merchants/${merchantID}/items/${itemID}`)) as any

        return { success: true }
      } catch (err) {
        return err
      }
    },

    async createItem(merchantID: number, item: Omit<Items, 'id'>) {
      try {
        ;(await client.post(`/v1/merchants/${merchantID}/items`, { ...item, merchant_id: Number(merchantID) })) as any

        return { success: true }
      } catch (err) {
        return err
      }
    },
  }
}
