import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { t, date } from '@lingui/macro'
import Box from '@mui/material/Box'
import { NoResults, SearchResults } from '@Components/search'
import { SellerTransactionActions, TransactionDetailsActions } from '../../../store'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import Button from '@Components/button'
import { TransactionDetails } from './SellerTransactionsDetails'
import TextField from '@Components/textField'
import MenuItem from '@mui/material/MenuItem'
import { itensStatusCombo } from '../../../mocks/listComboStatus'
import Search from '@mui/icons-material/Search'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress'
import { Filter } from '@Components/filter'
import { parseISO } from 'date-fns'
import { RootState } from '../../../store/Store'
import DialogTitle from '@mui/material/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import { formatToDefaultCurrency } from 'src/utils/number'
import DialogContent from '@mui/material/DialogContent'

export interface transactionInterface {
  merchantId: number
  paramsTransactions?: any
  hideFilter?: boolean
  sellerName?: string
}

const { transactionSeller, updateStatusTransactions, clear } = SellerTransactionActions
const { sellerTransactionMerchantDetails, clearDetails } = TransactionDetailsActions

const SellerTransactions: React.FC<transactionInterface> = ({
  merchantId,
  paramsTransactions,
  hideFilter,
  sellerName,
}: transactionInterface) => {
  const [openModal, setOpenModal] = useState(false)
  const [handleComboStatus, setHandleComboStatus] = useState(false)

  const { i18n } = useSelector((state: RootState) => state.translation)
  const { loadingDetails, transactionDetailsResult, errorDetails } = useSelector(
    (state: RootState) => state.transactionDetails,
  )
  const { loading, transactionResult, error, errorCombo } = useSelector((state: RootState) => state.sellerTransaction)
  const user = useSelector((state: RootState) => state.user)

  let formattingResult

  const dataInfo = {
    credentials: user,
    idMerchant: merchantId,
  }

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(clear())
    dispatch(transactionSeller(dataInfo, { ...paramsTransactions }))
  }, [])

  const onChangePage = (nextPage: any) => {
    dispatch(transactionSeller(dataInfo, { ...paramsTransactions, page: nextPage }))
  }

  const onChangeFilter = (filterParams: any) => {
    if (Object.keys(filterParams).length > 0) {
      dispatch(transactionSeller(dataInfo, { ...filterParams }))
    } else {
      dispatch(transactionSeller(dataInfo))
    }
  }

  const handlerIcon = (item: any) => {
    dispatch(clearDetails())
    setOpenModal(true)
    let credentials = {
      operation: item.operation ? item.operation : '',
      authorization_id: item.authorization_id ? item.authorization_id : '',
      user: user,
    }

    dispatch(sellerTransactionMerchantDetails(credentials, merchantId, item.id))
    // if (item.meta && Object.keys(item.meta).length > 0) {
    //   item.meta.map(obj => {
    //     if (obj.name === 'MarketplaceAuthorization') {
    //       paramDetails.marketplaceAuthorization = obj.value
    //     }

    //     if (obj.name === 'MarketplaceTransaction') {
    //       paramDetails.marketplaceTransaction = obj.value
    //     }
    //     return true
    //   })
    //   dispatch(sellerTransactionMktDetails(credentials, paramDetails))
    // } else if (item.authorization_id !== null || item.order_id !== null) {
    //   dispatch(sellerTransactionMerchantDetails(credentials, merchantId, item.id))
    // }
  }

  const handleClose = () => {
    setOpenModal(false)
  }

  const handleCloseError = () => {
    dispatch(transactionSeller(dataInfo))
  }

  const handleChangeStatus = (item: any, event: any) => {
    const updateStatus = {
      credential: user,
      data: {
        id: item.id,
        merchantId: merchantId,
        status: event.target.dataset.value,
      },
    }
    dispatch(updateStatusTransactions(updateStatus))
    dispatch(transactionSeller(dataInfo, { page: transactionResult.current_page }))
  }

  const statusCombo = (item: any) => {
    setHandleComboStatus(errorCombo ? true : false)
    let currentSelect
    itensStatusCombo.map((res, i) => {
      if (res.value === item.status) {
        currentSelect = item.status
      }
      return currentSelect
    })
    return (
      <Box>
        {!errorCombo ? (
          <Box>
            <TextField
              select
              value={currentSelect}
              //eslint-disable-next-line no-restricted-globals
              onChange={() => handleChangeStatus(item, event)}
            >
              {itensStatusCombo.map((res, i) => {
                return (
                  <MenuItem key={`combo_${i}`} value={res.value}>
                    {i18n._(`${res.name}`)}
                  </MenuItem>
                )
              })}
            </TextField>
          </Box>
        ) : (
          <Box>
            <Dialog open={errorCombo && handleComboStatus}>
              <Box display='flex' flexDirection='column'>
                <NoResults message={i18n._(t`Requisição não efetuada. Por favor tente mais tarde`)} />
              </Box>
              <DialogActions>
                <Button onClick={handleCloseError}>
                  <span>{i18n._(t`Fechar`)}</span>
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        )}
      </Box>
    )
  }

  const iconSearch = (item: any) => {
    return (
      <IconButton onClick={() => handlerIcon(item)} size='large'>
        <Search key={item.id} />
      </IconButton>
    )
  }

  const format = (item, style, type) => {
    // validando formato da data
    const patternData = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/
    const patternData2 = /^[0-9]{1}\/[0-9]{2}\/[0-9]{4}$/
    const patternData3 = /^[0-9]{2}\/[0-9]{1}\/[0-9]{4}$/
    const patternData4 = /^[0-9]{1}\/[0-9]{1}\/[0-9]{4}$/

    if (item === '' || item === null || item === undefined) return
    switch (type) {
      case 'number':
        if (isNaN(item)) return item
        return formatToDefaultCurrency(Number(item))
      case 'date':
        if (patternData.test(item) || patternData2.test(item) || patternData3.test(item) || patternData4.test(item))
          return item
        return i18n._(
          t`${date(new Date(parseISO(item)), {
            month: 'numeric',
            year: 'numeric',
            day: 'numeric',
          })}`,
        )
    }
  }

  if (transactionResult && transactionResult.items) {
    formattingResult = { ...transactionResult }

    transactionResult.items.map((res, i) => {
      formattingResult.items[i].advanced_amount = format(res.advanced_amount, 'currency', 'number')
      formattingResult.items[i].discount = <div>{format(res.discount, 'currency', 'number')}</div>
      formattingResult.items[i].event_date = format(res.event_date, '', 'date')
      formattingResult.items[i].gross_value = format(res.gross_value, 'currency', 'number')
      formattingResult.items[i].net_value = format(res.net_value, 'currency', 'number')
      formattingResult.items[i].payment_date = format(res.payment_date, '', 'date')
      formattingResult.items[i].receipt_date = format(res.receipt_date, '', 'date')
      formattingResult.items[i].received_value = format(res.received_value, 'currency', 'number')
    })
  }

  const hasNoResult = !loading && (error || !transactionResult || transactionResult.total_items === 0)

  return (
    <Box display='flex' flexDirection='column' data-testid='transaction-page-container'>
      <Filter onFilter={onChangeFilter} filterHide={hideFilter} />

      {hasNoResult && <NoResults />}

      {transactionResult && transactionResult?.items?.length > 0 && (
        <SearchResults
          header={[
            { title: '', prop: '', funcContent: iconSearch },
            { title: i18n._(t`ID de autorização`), prop: 'id' },
            { title: i18n._(t`data origem`), prop: 'event_date' },
            { title: i18n._(t`data vencimento`), prop: 'payment_date' },
            { title: i18n._(t`operação`), prop: 'operation_type_id' },
            { title: i18n._(t`valor bruto`), prop: 'gross_value' },
            { title: i18n._(t`desconto taxa`), prop: 'discount' },
            { title: i18n._(t`valor líquido`), prop: 'net_value' },
            { title: i18n._(t`status`), prop: '', funcContent: statusCombo },
            { title: i18n._(t`data recebimento`), prop: 'received_value' },
            { title: i18n._(t`desconto antecipação`), prop: 'advanced_amount' },
            { title: i18n._(t`valor recebido`), prop: 'received_value' },
          ]}
          data={formattingResult}
          changePage={onChangePage}
          loading={loading}
        />
      )}
      <Dialog onClose={handleClose} open={openModal}>
        {!loadingDetails ? (
          <>
            <DialogTitle sx={{ m: 0, p: 2 }} color='primary'>
              <Box display='flex' justifyContent='space-between'>
                <Typography variant='h6'>{i18n._(t`Detalhes`)}</Typography>
                <IconButton aria-label='close' onClick={handleClose} color='primary'>
                  <CloseIcon />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent>
              <TransactionDetails
                dataItens={transactionDetailsResult}
                statusLoad={loadingDetails}
                statusError={errorDetails}
                sellerName={sellerName}
              />
            </DialogContent>
          </>
        ) : (
          <Box display='flex' p={4} justifyContent='center' alignItems='center'>
            <CircularProgress color='success' />
          </Box>
        )}
      </Dialog>
    </Box>
  )
}

export { SellerTransactions }
